import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const ContacPage = () => {

    const [status, setStatus] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [country, setCountry] = useState('Argentina')
    const [phone, setPhone] = useState(undefined)
    const [message, setMessage] = useState(undefined)


    const submitForm = async (ev) => {
        ev.preventDefault();

        const data = {
            email,
            name,
            country,
            phone,
            message
        }

        if (email && name && country && phone && message) {
            await fetch(`/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => {
                if (res.ok) {
                    setStatus("SUCCESS")
                } else {
                    setStatus("ERROR")
                }
            })
        }

        setName("")
        setEmail("")
        setCountry("")
        setMessage("")
        setPhone("")
        // setStatus(undefined)

    }

    return (
        <Layout page={'index'}>
            <SEO title="Contacto" />
            <div className="contacto-steps-how-it-works">
                <div className="title-container m-b-50">
                    <h2 className="text-white">Contacto</h2>
                </div>
                <div className="container">
                    {!!status && <div className="form-result">
                        {status === "SUCCESS" && <p>Gracias por tu consulta. Nos comunicaremos a la brevedad.</p>}
                        {status === "ERROR" && <p>Ocurrió un error. Intenta nuevamente en unos minutos. </p>}
                    </div>}
                    <form onSubmit={submitForm} className="contact-form">
                        <input maxlength="100" type="text" onChange={(e) => setName(e.target.value)} value={name} required placeholder="Nombre y Apellido*" name="Nombre" />
                        <input maxlength="100" type="email" onChange={(e) => setEmail(e.target.value)} value={email} required placeholder="Email*" name="_replyto" />
                        <select maxlength="50" name="Pais" defaultValue="Argentina" onChange={(e) => setCountry(e.target.value)} value={country} >
                            <option value="Argentina">Argentina</option>
                            <option value="Mexico">México</option>
                            <option value="Otro">Otro</option>
                        </select>
                        <input maxlength="20" onChange={(e) => setPhone(e.target.value)} value={phone} type="text" required placeholder="Teléfono*" name="Teléfono" />
                        <textarea maxlength="500" onChange={(e) => setMessage(e.target.value)} value={message} name="Consulta" id="" required cols="30" rows="10" placeholder="Detalle de consulta*"></textarea>
                        <div>
                            <button type="submit" className="btn-primary">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default ContacPage
